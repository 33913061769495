import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { SectionComponent } from './section/section.component';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { HttpClientModule } from '@angular/common/http';
import { SectionTwoComponent } from './section-two/section-two.component';
import { PlanejamentoEstrategicoComponent } from './planejamento-estrategico/planejamento-estrategico.component';
import { FooterComponent } from './footer/footer.component';
import { SafePipe } from './pipes/safe.pipe';
import { NgxMaskModule } from 'ngx-mask';
import { ParceriasComponent } from './parcerias/parcerias.component';
import { ServicosComponent } from './servicos/servicos.component';
import { QuemSomosComponent } from './quem-somos/quem-somos.component'
import { DeferLoadModule } from '@trademe/ng-defer-load';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    SectionComponent,
    SectionTwoComponent,
    PlanejamentoEstrategicoComponent,
    FooterComponent,
    SafePipe,
    ParceriasComponent,
    ServicosComponent,
    QuemSomosComponent
  ],
  imports: [
    BrowserModule,
    MatIconModule,
    DeferLoadModule,
    MatButtonModule,
    HttpClientModule,
    NgxMaskModule.forRoot(),
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
