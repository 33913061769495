<footer id="fale-conosco">
    <div class="footer-root">
        <div class="contato container">
            <h2>Contato:</h2>
            <div class="contato-content">
                <div class="telefones">
                    <div class="footer-box-text footer-box-text-contact">
                        <a class="endereco-link" [href]="helper.enderecoMapsUrl">
                            <mat-icon>fmd_good</mat-icon>
                            <p target="_blank">{{helper.endereco}}</p>
                        </a>
                        <a *ngFor="let contato of helper.contatos" class="endereco-link" target="_blank" [href]="contato.href">
                            <!-- <mat-icon>phone</mat-icon> -->
                            <mat-icon svgIcon="whatsapp"></mat-icon>
                            <p target="_blank">{{contato.numero | mask:contato.mascara}} <span *ngIf="contato.observacao">({{contato.observacao}})</span></p>
                        </a>
                        <a *ngIf="helper.email" class="endereco-link" [href]="'mailto:'+helper.email">
                            <mat-icon>email</mat-icon>
                            <p target="_blank">{{helper.email}}</p>
                        </a>
                    </div>
                </div>
                <div class="maps-area">
                    <div class="maps-iframe" (deferLoad)="carregarIframe = true">
                        <iframe *ngIf="carregarIframe" [src]="helper.enderecoIframe | safe" frameborder="0" style="border:0;"
                            allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
                    </div>
                </div>
            </div>
        </div>
        <div class="container">
            <div class="footer-border"></div>
            <div class="copyright">
                <p>&copy; Ximenes Mídias - Todos os direitos reservados.</p>
            </div>
        </div>
    </div>
</footer>