import { Component, OnInit } from '@angular/core';
import { HelperService } from '../services/helper.service';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  constructor(public helper: HelperService, iconRegistry: MatIconRegistry, sanitizer: DomSanitizer) { 
    iconRegistry.addSvgIcon('whatsapp',sanitizer.bypassSecurityTrustResourceUrl('assets/img/icones/whatsapp.svg'));
  }

  ngOnInit(): void {
  }

  carregarIframe = false;
}
