<div id="producao-audiovisual" class="anchor"></div>
<div class="root">
    <div class="secao-1">
        <div class="content">
            <!-- <mat-icon svgIcon="wave"></mat-icon> -->
            <div class="container">
                <div class="header" id="cases">
                    <h1>Produção Audiovisual</h1>
                    <p>Uma produtora oferecendo o que há de melhor em recursos tecnológicos para áudio e vídeo, de modo que sua marca seja projetada com solidez e criatividade, despertando o interesse e efetivando a conversão do seu cliente a curto, médio e longo prazo.<br>
                        Planejamos estratégias que incluem a criação e desenvolvimento de vídeos institucionais para empresas, comerciais, infomerciais, produções completas para televisão, internet e transmissão simultânea
                        “Lives”.<br>
                        Tudo com a melhor qualidade, unindo tecnologia de ponta, compromisso e dedicação com nossos clientes e parceiros.
                        </p>
                </div>
                <div class="portifolio">
                    <div class="card" *ngFor="let item of portifolio; let i = index">
                        <picture>
                            <source [srcset]="'https://i.ytimg.com/vi_webp/'+item.youtubeId+'/mqdefault.webp'"
                                type="image/webp" />
                            <img [src]="'https://i.ytimg.com/vi/'+item.youtubeId+'/mqdefault.jpg'" loading="lazy" />
                        </picture> 
                        <div class="descricao">
                            <h2>{{item.titulo}}</h2>
                            <p>{{item.empresa}}</p>
                        </div>
                        <a [href]="'https://youtu.be/'+item.youtubeId" target="_blank" mat-stroked-button
                                color="warn">ASSISTIR <mat-icon>play_circle</mat-icon></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>