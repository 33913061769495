<div class="whatsapp-button">
    <div class="whatsapp-logo" [class.shake]="shake">
        <a [href]="helper.getTelefoneContato().href" target="_blank">
            <picture>
                <source srcset="assets/img/icones/whatsapp-oficial.webp" type="image/webp" />
                <img src="assets/img/icones/whatsapp-oficial.png" lading="lazy"/>
            </picture>
        </a>
    </div>
</div>

<app-home></app-home>

<app-quem-somos></app-quem-somos>

<app-servicos></app-servicos>

<app-planejamento-estrategico></app-planejamento-estrategico>

<app-section></app-section>

<app-section-two></app-section-two>

<app-parcerias></app-parcerias>

<app-footer></app-footer>