import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class HelperService {

  constructor() {
  }

  //Contatos:
  public readonly contatos: ContatoTelefonico[] = [
    new ContatoTelefonico('11942961534', ITipoContatoTelefonico.Whatsapp, true, 'SP - Matriz'),
    new ContatoTelefonico('41991383990', ITipoContatoTelefonico.Whatsapp, true, 'Curitiba - Filial'),
  ];
  public readonly email?: string = "ximenesmidias@gmail.com";

  //Endereço
  public readonly endereco: string = "R. Angelo Massignan, 884, Unidade 12, Bloco 4 - São Braz, Curitiba - PR, 82315-000";
  public readonly enderecoMapsUrl: string = "https://goo.gl/maps/1VpoKQNGQspcVZpYA";
  public readonly enderecoIframe: string = "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3603.8070871033983!2d-49.35249429999999!3d-25.411267300000002!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94ce5dd209a422e7%3A0x331b56456de7eda8!2sXimenes%20M%C3%ADdias!5e0!3m2!1spt-BR!2sbr!4v1675343195512!5m2!1spt-BR!2sbr";

  //Redes sociais
  public readonly facebookUrl: string = "https://www.facebook.com/ximenesmidias";
  public readonly instagramUrl: string = "https://www.instagram.com/ximenesmidias";
  public readonly tiktokUrl?: string;
  public readonly linkedinUrl: string = "https://www.linkedin.com/in/arles-ximenes-85933521/";
  public readonly youtubeUrl: string = "https://www.youtube.com/channel/UCh-kAvBkz9OP3OzjV0T-2Ow";

  //Informações que serão exibidas:
  public readonly mostrarWhatsappCabecalho = false;

  //Link para aplicativo de compra:
  public readonly comprarLink?: string;

  //Link para baixar aplicativos:
  public readonly appleStoreLink?: string;
  public readonly playStoreLink?: string;

  public getTelefoneContato(): ContatoTelefonico {
    return this.contatos[0];
  }

}


export class ContatoTelefonico {
  constructor(
    readonly numero: string,
    readonly tipo: ITipoContatoTelefonico,
    readonly PossuiWhatsApp: boolean,
    readonly observacao?: string) {
  }

  get href() {
    switch (this.tipo) {
      case ITipoContatoTelefonico.Celular:
      case ITipoContatoTelefonico.Telefone:
        return 'tel:' + this.numero;
      case ITipoContatoTelefonico.Whatsapp:
        return 'https://wa.me/55' + this.numero;
    }
  }

  get tipoTextual() {
    switch (this.tipo) {
      case ITipoContatoTelefonico.Celular:
        return 'Celular';
      case ITipoContatoTelefonico.Telefone:
        return 'Telefone';
      case ITipoContatoTelefonico.Whatsapp:
        return 'Whatsapp';
    }
  }

  get mascara() {
    switch (this.tipo) {
      case ITipoContatoTelefonico.Celular:
      case ITipoContatoTelefonico.Whatsapp:
        return '(00) 00000-0000';
      case ITipoContatoTelefonico.Telefone:
        return '(00) 0000-0000';
    }
  }
}

export enum ITipoContatoTelefonico {
  Celular = 'C',
  Telefone = 'T',
  Whatsapp = 'W'
}