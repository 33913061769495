import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-planejamento-estrategico',
  templateUrl: './planejamento-estrategico.component.html',
  styleUrls: ['./planejamento-estrategico.component.css']
})
export class PlanejamentoEstrategicoComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
