<div id="planejamento-estrategico" class="anchor"></div>
<div class="root">
    <div class="content">
        <h2>Planejamento estratégico</h2>
        <div class="hr"></div>
        <p>Em uma comunicação de sucesso é necessário conhecimento global e específico da identidade, conceito e valores
            de sua marca. Além disso, estudar o seu consumidor ideal, definir a melhor campanha publicitária, fidelizar
            antigos clientes e alcançar novos. <br>
            Pensando nisso, oferecemos as melhores estratégias de branding marketing, definimos o seu público-alvo
            (target), investimos na produção de conteúdos atuais e segmentados de acordo com o seu investimento,
            (budget), de modo que você tenha de forma clara e precisa quais serão os resultados alcançados.<br>
            Tudo isso porque oferecemos um plano de mídia 360° que reunirá uma equipe especializada para prestar a
            melhor consultoria.
        </p>
    </div>
    <div class="image">
        <picture>
            <source srcset="assets/img/fundos/alvo.webp"  type="image/webp" />
            <img src="assets/img/fundos/alvo.jpg" loading="lazy" />
        </picture>

    </div>
</div>