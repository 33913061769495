<div id="intermediacao-de-midia" class="anchor"></div>
<div class="root">
    <div class="image">
        <picture>
            <source srcset="assets/img/fundos/out-of-home.webp" type="image/webp" />
            <img src="assets/img/fundos/out-of-home.jpg" loading="lazy"/>
        </picture>
    </div>
    <div class="content">
        <h2>Intermediação de mídia</h2>
        <div class="hr"></div>
        <p>Projetamos a sua marca com o melhor plano de exposição midiática, voltado exclusivamente para o seu público-alvo. Oferecemos estratégias ideais nas mais diversas plataformas, e melhor: utilizando o custo negociado, o que permite que possamos adaptar nossos produtos às suas necessidades e de maneira flexível (te oferecendo o melhor custo benefício). A TV, agora, ao seu alcance. Consulte-nos e surpreenda-se com nossos planos!</p>
        <ul>
            <li>TV em todo território nacional (canais abertos e fechados).</li>
            <li>PayTV.</li>
            <li>Rádios segmentadas e diversificadas.</li>
            <li>Mídia Out of home (TV's em prédios, espaços corporativos e transportes públicos).</li>
            <li>Over the top (TV via internet).</li>
        </ul>
    </div>
</div>