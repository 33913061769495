import { AfterViewInit, Component } from '@angular/core';
import { HelperService } from './services/helper.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements AfterViewInit {

  constructor(public helper: HelperService) {
  }
  ngAfterViewInit(): void {
    setInterval(()=>{
      this.shake = !this.shake;
    }, 2000);
  }

  shake: boolean = true;
}
