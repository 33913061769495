import { AfterViewChecked, Component, HostListener, ViewChild } from '@angular/core';
import { MatIcon, MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-section',
  templateUrl: './section.component.html',
  styleUrls: ['./section.component.css']
})
export class SectionComponent implements AfterViewChecked {

  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer) {
    this.matIconRegistry.addSvgIcon('wave', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/img/wave-effect.svg'));
  }

  ngAfterViewChecked(): void {
    this.WaveEffect_Toogle_Stroke()
  }

  divSectionSize = "0%";
  portifolio: Portifolio[] = [
    {
      titulo: 'Infomercial',
      empresa: 'Campanha de Prevenção COVID19',
      youtubeId: 'Lc7xQTBf_tU'
    },
    {
      titulo: 'Comercial de TV',
      empresa: 'Comercial Suplementos',
      youtubeId: 'wQ4ffDcmooc'
    },
    {
      titulo: 'Institucional',
      empresa: 'Empresa de Investimentos',
      youtubeId: 'bTWC55_c1Qg'
    },
    {
      titulo: 'Comercial de TV',
      empresa: 'Rede de Farmácias',
      youtubeId: 'HwpDe6aBdHA'
    },
    {
      titulo: 'Institucional',
      empresa: 'Assistência Funeral',
      youtubeId: 'Wt9Gt2sGLXQ'
    },
    {
      titulo: 'Institucional',
      empresa: 'Mini Mercados',
      youtubeId: '_AoghvNB0vA'
    },
    {
      titulo: 'Comercial',
      empresa: 'Corretora de Câmbio',
      youtubeId: '6mN9LxmEYaU'
    },
    {
      titulo: 'Comercial',
      empresa: 'Rede de Hoteis',
      youtubeId: '91Xjj9d9j-I'
    },
    {
      titulo: 'Merchan Break',
      empresa: 'Acquamatic do Brasil',
      youtubeId: 'o-BHcymZ48o'
    },
    {
      titulo: 'Programa de TV',
      empresa: 'Vambora viajar na expo turismo paraná',
      youtubeId: 'heZlSxXL2rU'
    },
    {
      titulo: 'Merchan',
      empresa: 'Rede TV',
      youtubeId: 'qeZ84jFiCtA'
    },
    {
      titulo: 'Merchan',
      empresa: 'TV Gazeta',
      youtubeId: 'C33tzRhyXRQ'
    },
  ];

  @ViewChild('wave') waveRef: MatIcon | undefined;

  private WaveEffect_Toogle_Stroke_Activate = false;
  private WaveEffect_Toogle_Stroke() {
    if (this.WaveEffect_Toogle_Stroke_Activate) return;
    this.WaveEffect_Toogle_Stroke_Activate = true;
    let lastClasseId = 0;
    let emFrente = true;
    setInterval(() => {
      var el = <HTMLElement | undefined>document.querySelector('[svgicon="wave"] svg path.st' + lastClasseId);
      if (emFrente) {
        if (el) {
          var tamanhoAtual = parseFloat(el.style.strokeWidth.replace('px', ''));
          if (tamanhoAtual < 3) {
            el.style.strokeWidth = (tamanhoAtual + 0.1).toString() + 'px';
          }
          else
            lastClasseId++;
        }
      }
      else {
        if (el) {
          var tamanhoAtual = parseFloat(el.style.strokeWidth.replace('px', ''));
          if (tamanhoAtual > 1) {
            el.style.strokeWidth = (tamanhoAtual - 0.1).toString() + 'px';
          }
          else
            lastClasseId--;
        }
      }
      if (lastClasseId == 0 && !emFrente) {
        emFrente = true;
      }
      if (lastClasseId == 10 && emFrente) {
        emFrente = false;
        lastClasseId--;
      }
    }, 10)
  }

}

export interface Portifolio {
  titulo: string;
  empresa: string;
  youtubeId: string;
  descricao?: string;
}
