<div class="container">
    <h1>Parceiros</h1>
    <hr/>
    <div class="lista-parceiros">
        <div class="parceiro" *ngFor="let parceiroStyle of listaLogosParceiros">
            <div class="img" [style.background-image]="parceiroStyle"></div>
        </div>
    </div>
</div>

