import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-parcerias',
  templateUrl: './parcerias.component.html',
  styleUrls: ['./parcerias.component.css']
})
export class ParceriasComponent implements OnInit {

  constructor() {
    let listaArquivos = [
      "alphafm.png",
      "antena1.png",
      "axn.png",
      "band sports.png",
      "band.png",
      "band_96fm.jpg",
      "band_news.png",
      "band_news_fm.jpg",
      "gazeta.png",
      "gazeta_fm.png",
      "nativa.png",
      "novabrasil_fm.png",
      "r7_logo.png",
      "record-tv-logo.png",
      "record_news.png",
      "redetv.png",
      "sbt.png",
      "sonytv.png",
      "tnt.png"
    ];
    this.listaLogosParceiros = listaArquivos.map(a=>"url('assets/img/parceiros/"+a+"')");
   }

  ngOnInit(): void {
  }


  listaLogosParceiros: string[];

}
