import { Component, OnInit } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-servicos',
  templateUrl: './servicos.component.html',
  styleUrls: ['./servicos.component.css']
})
export class ServicosComponent implements OnInit {

  constructor(private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer) { 

      this.matIconRegistry.addSvgIcon('audiovisual',this.domSanitizer.bypassSecurityTrustResourceUrl('assets/img/icones/reprodutor-de-video.svg'))
    }

  ngOnInit(): void {
  }

}
