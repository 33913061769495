<div class="anchor" id="quem-somos"></div>
<div class="root">
    <div class="bg">
        <div class="op">
            <div class="container">
                <h1>AGÊNCIA</h1>
                <div class="hr"></div>
                <p>
                    Ximenes Mídias é uma Agência 360° com produção audiovisual, marketing digital e intermediação de mídias que inicia suas atividades com alta tecnologia e inovação.<br>
                    Contamos com equipamentos de última geração e profissionais altamente qualificados (com um know how de mais de 30 anos).<br>
                    Nosso objetivo é atuar no mercado publicitário com foco em criação, institucionalização e comercialização de mídias e marcas.<br>
                    Temos um sólido relacionamento com os principais veículos de comunicação em todo o território nacional e internacional. Com isso, oferecemos as melhores condições do mercado; direcionando seu produto, serviço e marca ao seu público-alvo.<br>
                    Através do planejamento e execução de campanhas exclusivas elaboradas para a TV aberta, TV fechada, rádio, mídias online e impressos faremos com que a sua empresa tenha uma projeção de destaque no mercado. Além disso, oferecemos estudos e orientação profissional sobre quando e onde investir no universo midiático de acordo com sua necessidade bem como o acompanhamento dos RESULTADOS almejados, de modo que sua empresa alcance o MELHOR desempenho!<br>
                    Nosso portfólio engloba os melhores veículos de comunicação do mercado.<br>
                    <b>Sejam bem-vindos à Ximenes Mídias.</b>
                </p>
            </div>
            
        </div>
        
    </div>
</div>