<div id="o-que-fazemos" class="anchor">

</div>
<div class="root" >
    <div class="servicos">
        <div class="container">
            <h1>O que fazemos</h1>
            <div class="hr"></div>
            <div class="categorias">
                <a href="#planejamento-estrategico" class="categoria">
                    <div class="logo">
                        <img src="assets/img/icones/planejamento.png" />
                    </div>
                    <h1>Planejamento<br/>Estratégico</h1>
                </a>
                <a href="#producao-audiovisual" class="categoria">
                    <div class="logo">
                        <img src="assets/img/icones/claquete.png" />
                        <!-- <mat-icon svgIcon="audiovisual"></mat-icon> -->
                    </div>
                    <h1>Produção<br/>Audiovisual</h1>
                </a>
                <a href="#intermediacao-de-midia" class="categoria">
                    <div class="logo">
                        <img src="assets/img/icones/outdoor.png" />
                    </div>
                    <h1>Intermediação<br/>de Mídia</h1>
                </a>
            </div>
        </div>
    </div>
</div>