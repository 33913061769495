import { AfterViewInit, Component, HostListener } from '@angular/core';
import { HelperService } from '../services/helper.service';

declare var Typewriter: any;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements AfterViewInit {

  constructor(public helper: HelperService) {
  }
  ngAfterViewInit(): void {
    var tw = new Typewriter('#typewriter', {
      strings: ['PESSOAS','HISTÓRIAS'],
      autoStart: true,
      loop: true,
      delay: 75,
      cursor: ''
    });

    setTimeout(() => {
      this.hideFrase = false;
    }, 5000);

    
  }

  @HostListener('window:scroll', ['$event']) // for window scroll events
  onScroll(event:any) {
    this.barOpacity = window.pageYOffset < (window.innerHeight - 80);
  }

  barOpacity = true;
  hideFrase = true;

  collapseActive = false;
  collapseAtivarAnimacao = false;

  alternarMenu(delay: number = 0) {
    if(window.innerWidth < 768){
      if (!this.collapseActive) {
        this.collapseActive = !this.collapseActive;
        setTimeout(() => {
          this.collapseAtivarAnimacao = !this.collapseAtivarAnimacao;
        }, 10);
      }
      else {
        setTimeout(() => {
          this.collapseAtivarAnimacao = !this.collapseAtivarAnimacao;
          setTimeout(() => {
            this.collapseActive = !this.collapseActive;
          }, 500);
        }, delay);
      }
  
    }
    else{
      this.collapseActive = false;
    }
   
  }

}
