<div class="home" id="home">
    <div class="main-area">
        <div class="video-area">
            <video src="assets/video/filme_background.mp4" muted [muted]="true" autoplay playsinline loop></video>
        </div>
        <div class="video-op"></div>
        <div class="content">
            <div class="frase">
                <h2 class="show">CONECTANDO <b class="destaque" id="typewriter"></b></h2>
                <h2 [class.show]="!hideFrase">GENTE PROMOVENDO GENTE.</h2>
            </div>
        </div>
    </div>
</div>
<div class="menu-principal" [class.open]="collapseActive" [class.opacity]="barOpacity">
    <div class="logo">
        <img src="assets/img/logo/white-h.svg" *ngIf="!collapseActive"/>
        <img src="assets/img/logo/black-h.svg" *ngIf="collapseActive"/>
    </div>
    <div class="hamburguer-menu">
        <div class="btn" [ngClass]="{'active':collapseAtivarAnimacao,'not-active':!collapseAtivarAnimacao}" (click)="alternarMenu()">
            <span></span>
            <span></span>
            <span></span>
        </div>
    </div>
    <div class="collapse-menu">
        <nav>
            <a href="#home" (click)="alternarMenu()">Home</a>
            <a href="#quem-somos" (click)="alternarMenu()">Agência</a>
            <a href="#o-que-fazemos" (click)="alternarMenu()">Atuação</a>
            <a href="#cases" (click)="alternarMenu()">Cases</a>
        </nav>
        <div class="social-icons" *ngIf="!collapseActive">
            <a target="_blank" [href]="helper.facebookUrl">
                <img  src="assets/img/logo/facebook/black/f_logo_RGB-Black_58.png" />
            </a>
            <a target="_blank" [href]="helper.instagramUrl">
                <img src="assets/img/logo/instagram/instagram_logo_black_58.png" />
            </a>
            <a target="_blank" [href]="helper.tiktokUrl" *ngIf="helper.tiktokUrl">
                <img src="assets/img/logo/tiktok/tiktok-black.svg" />
            </a>
            <a target="_blank" [href]="helper.linkedinUrl">
                <img src="assets/img/logo/linkedin/LI-In-Bug-black-no-register-58.png" />
            </a>
            <a target="_blank" [href]="helper.youtubeUrl">
                <img src="assets/img/logo/youtube/yt_black_64.png" />
            </a>
        </div>
        <div class="social-icons" *ngIf="collapseActive">
            <a target="_blank" [href]="helper.facebookUrl">
                <img src="assets/img/logo/facebook/white/f_logo_RGB-White_58.png" />
            </a>
            <a target="_blank" [href]="helper.instagramUrl">
                <img src="assets/img/logo/instagram/instagram_logo_white_58.png" />
            </a>
            <a target="_blank" [href]="helper.tiktokUrl" *ngIf="helper.tiktokUrl">
                <img src="assets/img/logo/tiktok/tiktok-white.svg" />
            </a>
            <a target="_blank" [href]="helper.linkedinUrl">
                <img src="assets/img/logo/linkedin/LI-In-Bug-white-no-register-58.png" />
            </a>
            <a target="_blank" [href]="helper.youtubeUrl">
                <img src="assets/img/logo/youtube/yt_white_64.png" />
            </a>
        </div>

    </div>
</div>